import ru from './ru.json';
import uz from './uz.json';
import uzCyrillic from './uz-cyrillic.json';
import en from './en.json';


function sprintf() {
  var args = arguments,
    string = args[0],
    i = 1;
  return string.replace(/%((%)|s|d)/g, function (m) {
    var val = null;
    if (m[2]) {
      val = m[2];
    } else {
      val = args[i];
      switch (m) {
        case '%d':
          val = parseFloat(val);
          if (isNaN(val)) {
            val = 0;
          }
          break;
        default:
          console.log('default case');
      }
      i++;
    }
    return val;
  });
}


function t(str) {
  const dictionary = { en, ru, uz, 'uz-cyrillic': uzCyrillic };
  let lang = 'uz-cyrillic';
  if (typeof window !== `undefined`) {
    lang = window.location.pathname.replace(/\//g, '') || 'uz-cyrillic';
  }
  var translation = dictionary?.[lang]?.[str];

  if (translation === '') {
    translation = str;
    // console.warn('Translation is missing ' + str);
  } else if (!translation) {
    translation = "*" + str;
    // console.warn('Translation is not found at all ' + str);
  }
  var args = [].slice.call(arguments).slice(1);
  args.unshift(translation);
  return sprintf.apply(null, args);
};


export { t };